import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';
import { Section } from '../../styled-components';

import why1 from '../../images/why-1.png';
import why2 from '../../images/why-2.png';
import why3 from '../../images/why-3.png';
import WhyImg from '../../images/brand-icon-half.png';
import windowImg from '../../images/window.png';
import SquareImg from '../../images/square.png';
import circleImg from '../../images/circle.png';
import buildingImg from '../../images/building.png';

const Title = styled.h2`
  //font-size: 1.5rem;
  //color: ${props => props.theme.primaryColor};
  text-align: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    max-width: 50%;
  }
`
const BackPiece = styled.img`
  position: absolute;
  width: ${props => props.width ? props.width : "auto"};
  height: ${props => props.height ? props.height : "auto"};
  top: ${props => props.top ? props.top : "auto"};
  left: ${props => props.left ? props.left : "auto"};
  right: ${props => props.right ? props.right : "auto"};;
  bottom: ${props => props.bottom ? props.bottom : "auto"};
  object-fit: cover;
  object-position: center;
`

const Red = styled.span`
  color: ${props => props.theme.primaryColor};  
`
const InfoCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Paragraph = styled.p`
  width: 70%;
  text-align: center;
`
const ExtraCont = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6rem 0 8rem;
  flex-direction: column;
  @media(min-width: 768px){
    flex-direction: row;
  }
`
const ExtraItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    width: 25%;
    margin-bottom: 0rem;
  }
`
const Icon = styled.img`
  max-width: 80px;
  min-height: 65px;
  margin-bottom: 2rem;
`
const ExtraInfo = styled.p`
  text-align: center;
`
const Bold = styled.span`
  font-weight: bold;
`

export default ()=> {

  return(
    <Section>
     {/*} <BackPiece
        src={windowImg}
        alt=""
        top="-50px"
        left="10px"
        width="120px"
      />
      <BackPiece
        src={circleImg}
        alt=""
        top="50%"
        right="10px"
        width="120px"
      />    */}  
      <Container>
        <InfoCont>
          <Title>
            Revolucionamos la forma de <Red>vender propiedades</Red>
          </Title>
          <Paragraph>
            Somos una inmobiliaria digital que a través de tecnología y marketing de red, acelera el <Bold>proceso de venta de tu propiedad.</Bold>
          </Paragraph>
          <Paragraph>
            Queremos cambiar las reglas del corretaje, haciéndolo mas ágil, transparente y sencillo, enfocado en entregarte la mejor experiencia.
          </Paragraph>          
        </InfoCont>
        <ExtraCont>
          <ExtraItem>
            <Icon src={why1} alt="" />
            <ExtraInfo>
              Nos centramos en las <Bold>personas</Bold> y sus <Bold>necesidades</Bold>.
            </ExtraInfo>
          </ExtraItem>
          <ExtraItem>
            <Icon src={why2} alt="" />
            <ExtraInfo>
              Realizamos un servicio inmobiliario <Bold>ágil, rápido y cercano</Bold>.
            </ExtraInfo>
          </ExtraItem>
          <ExtraItem>
            <Icon src={why3} alt="" />
            <ExtraInfo>
              <Bold>Nos apoyamos en tecnología</Bold> y la ponemos a tu servicio.
            </ExtraInfo>
          </ExtraItem>                    
        </ExtraCont>        
      </Container>
    </Section>
  )
}