import React, { Fragment } from 'react';
import styled from 'styled-components';

import Hero from '../components/home/hero';
import Why from '../components/home/why';
import Why2 from '../components/home/why-2';
import How from '../components/home/how';
import Reviews from '../components/home/reviews';

import Properties from '../components/home/properties';
import About from '../components/home/about';
import OfficeInfo from '../components/home/office-info';
import Contact from '../components/contact';

import bgReview from '../images/pexels-cottonbro-4039184.jpg';

const itemsReview = [
  {
    id: null,
    src:"/team-6.jpg",
    review: "Excelente grupo de profesionales. Mi familia y yo estamos muy felices de haber dejado nuestra propiedad en sus manos. ¡Muchas gracias!",
    author: "Gabriel Vargas"
  },                      
  {
    id: null,
    src:"/team-2.jpg",
    review: "Muy eficientes. Es impresionante lo rápido que gestionaron mi proceso de venta y me asesoraron perfectamente en todo momento.",
    author: "Roberto Hidalgo"
  },
  {
    id: null,
    src:"/team-3.jpg",
    review: "¡Me ha parecido fantástico! Me han entregado una valorización gratuita en cosa de horas y con eso pudimos determinar el precio de venta de mi departamento. Realmente te hacen la vida más fácil.",
    author: "Amelia López"
  },      
]

const Title = styled.h2`
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 1rem;
  color: white;
`;

const ReviewText = styled.p`
  text-align: left;
`;

export default ()=> {

  return(
    <Fragment>
      <Hero />
      {/*<Why />
      <Why2 />*/}
      <How />
      <Reviews
        items={itemsReview.map(item => ({ ...item, review: <ReviewText>{item.review}</ReviewText> }))}
        bgImg={bgReview}
        title={<Title>Hunter Propiedades es reconocido por su rapidez, agilidad y cercanía.</Title>}
      />
   
      {/*  <About />  */}

      <Contact />
    </Fragment>
  )
}
